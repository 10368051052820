<template>
  <section class="bg-white p-4 mb-5 rounded-sm">
    <h5 class="font-weight-bold mb-3">
      {{ $t('sparkmembers.contents.views.editor.components.additional.components.visibility-card.title') }}
    </h5>
    <template v-if="step == 1">
      <p class="font-size-sm">
        {{ $t('sparkmembers.contents.views.editor.components.additional.components.visibility-card.paragraph.pt1') }}
        <strong class="text-primary">
          {{
            $t('sparkmembers.contents.views.editor.components.additional.components.visibility-card.paragraph.pt2', {
              status: visibility
                ? $t(
                    'sparkmembers.contents.views.editor.components.additional.components.visibility-card.paragraph.published'
                  )
                : $t(
                    'sparkmembers.contents.views.editor.components.additional.components.visibility-card.paragraph.hidden'
                  ),
            })
          }}</strong
        >
      </p>

      <hs-button class="mt-3" variant="outline-secondary" @click="onChangeNavigation">{{
        $t(
          'sparkmembers.contents.views.editor.components.additional.components.visibility-card.buttons.change-navigation'
        )
      }}</hs-button>
    </template>
    <template v-if="step === 2">
      <hs-radio class="mt-4" name="visibility" v-model="visibility" :value="true">
        {{ $t('sparkmembers.contents.views.editor.components.additional.components.visibility-card.radio.published') }}
      </hs-radio>
      <hs-radio class="mt-3" name="visibility" v-model="visibility" :value="false">
        {{ $t('sparkmembers.contents.views.editor.components.additional.components.visibility-card.radio.hidden') }}
      </hs-radio>
      <hs-button class="mt-3" variant="outline-secondary" @click="onSave">{{
        $t('sparkmembers.contents.views.editor.components.additional.components.visibility-card.buttons.save')
      }}</hs-button>
    </template>
  </section>
</template>

<script>
export default {
  name: 'VisibilityCard',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      step: 1,
      visibility: this.value,
    };
  },
  methods: {
    onChangeNavigation() {
      this.step++;
    },
    async onSave() {
      this.$emit('input', this.visibility);
      this.step = 1;
    },
  },
};
</script>
